import { type UseMutationResult } from '@tanstack/react-query'
import { Form, Formik } from 'formik'

import Button from '@components/buttons/button'
import FormTextField from '@components/form-fields/form-text-field'
import { type ApiReponseError } from '@interfaces/api/api'
import ExternalPricesForm from '@pages/booking-requests/components/external-prices-form/external-prices-form'
import {
  type OfferCreateFormInterfaces
} from '@pages/booking-requests/components/offer-create-form/offer-create-form.interfaces'
import { OfferSchema } from '@pages/booking-requests/components/offer-create-form/offer-create-form.validation'
import { OfferRoomOptions } from '@pages/booking-requests/components/offer-room-options'
import FormToggleField from '@root/components/form-fields/form-toggle-field/form-toggle-field'
import { BookingPlatform } from '@root/interfaces/api/offer'
import { usePostFileQuery } from '@root/services/api/resources/post-entry-file'
import { handleImageViolations } from '@services/tools/api-resources/violations'

const OfferCreateForm = ({ bookingRequest, onSubmit }: OfferCreateFormInterfaces) => {
  const initialValues = {
    externalPrices: [],
    label: '',
    manualMode: true,
    manualRooms: bookingRequest.roomsPaxes.map(() => ({ rates: [], room: null })),
    margin: 0,
    platform: BookingPlatform.HOTELBEDS,
    price: 0,
    rooms: bookingRequest.roomsPaxes.map(() => ({ rates: [], room: null }))
  }

  const { mutateAsync: uploadFileAsync } = usePostFileQuery('images')

  const handleSubmit = async (values, formikHelpers) => {
    const { setErrors, setSubmitting } = formikHelpers
    try {
      const submissionValues = { ...values }

      // Traiter chaque prix externe pour uploader les images et convertir les prix
      for (let i = 0; i < submissionValues.externalPrices.length; i++) {
        const price = submissionValues.externalPrices[i]

        // Convertir le prix en nombre
        submissionValues.externalPrices[i] = {
          ...price,
          price: Number(price.price * 100)
        }

        // Gérer l'upload d'image
        if (price.image && typeof price.image !== 'string') {
          const formData = new FormData()
          formData.append('file', price.image)

          try {
            const uploadedImageUrl = await uploadFileAsync(formData)
            submissionValues.externalPrices[i] = {
              ...submissionValues.externalPrices[i],
              image: uploadedImageUrl
            }
          } catch (e) {
            const err = e as UseMutationResult<ApiReponseError>
            const errorMessage = handleImageViolations(err?.data?.violations ?? [])
            setErrors({ [`externalPrices.${i}.image`]: errorMessage })

            return
          }
        }
      }

      onSubmit(submissionValues, formikHelpers)
    } catch (error) {
      console.error('Error during form submission:', error)
      setSubmitting(false)
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={OfferSchema}
    >
      {({ errors, isSubmitting, setFieldValue, values }) => {
        return (
          <Form className='flex h-full flex-col'>
            <div>
              <div className='bg-gray-50 px-4 py-6 sm:px-6'>
                <div className='flex items-start justify-between space-x-3'>
                  <div className='space-y-1'>
                    <div className='text-base font-semibold leading-6 text-gray-900'>
                      {'Ajouter une offre'}
                    </div>
                  </div>

                  <FormToggleField label='Booking manuel' name='manualMode' />
                </div>
              </div>

              <div className='space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0'>
                <div className='space-y-2 px-4 py-5'>
                  {values.manualMode && (
                    <FormTextField label='Plateforme de booking' name='platform' required={values.manualMode} />
                  )}

                  <FormTextField label='Label' name='label' required />
                </div>

                <div className='py-5'>
                  <ExternalPricesForm
                    errors={errors}
                    externalPrices={values.externalPrices}
                  />
                </div>

                <div>
                  {bookingRequest.roomsPaxes.map((room, index) => (
                    <OfferRoomOptions
                      bookingRequest={bookingRequest}
                      errors={errors}
                      index={index}
                      key={index}
                      room={room}
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div className='flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6'>
              <div className='flex justify-end space-x-3'>
                <Button
                  isLoading={isSubmitting}
                  type='submit'
                >
                  {'Ajouter'}
                </Button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default OfferCreateForm
