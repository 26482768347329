import { UsersIcon } from '@heroicons/react/24/outline'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResource from '@components/api-resource'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layouts/layout/layout'
import Modal from '@components/modals/modal'
import SectionTitle from '@components/section-title/section-title'
import ApiResourceCreate from '@root/components/api-resource/api-resource-create'
import useApiResource from '@services/api/definition'
import { captureException } from '@services/exceptions/capture-exception'

const InvitationsList = () => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'my-invitations' })
  const [openCreate, setOpenCreate] = useState(false)

  const collectionDefinition = useApiResource('invitations')

  const breadcrumbs = [
    { href: '', name: translateResource('title') }
  ]

  const queryClient = useQueryClient()
  const onReload = () => {
    queryClient.refetchQueries({ queryKey: ['my-invitations'] }).catch(captureException)
  }

  const onEdit = () => {
    onReload()
    setOpenCreate(false)
  }

  const onCreateClick = () => {
    setOpenCreate(true)
  }

  return (
    <Layout title={translateResource('title')}>
      <Guard>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle icon={UsersIcon} title={translateResource('title')} />

        <ApiResource
          createdAt={'desc'}
          customFieldsToDisplay={['code', 'inviteeName', 'used', 'usedAt', 'createdAt']}
          name={'my-invitations'}
          withCreateOption={onCreateClick}
        />

        <>
          <Modal center open={openCreate} setOpen={setOpenCreate} size='large' title={translateResource('create')}>
            {collectionDefinition && <ApiResourceCreate definition={collectionDefinition} onEdit={onEdit} />}
          </Modal>
        </>
      </Guard>
    </Layout>
  )
}

export default InvitationsList
