import { ArrowDownOnSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { ErrorMessage, useFormikContext } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { type FormMultiImageFieldProps } from '@components/form-fields/form-multi-image-field/form-multi-image-field.interfaces'
import { type Image } from '@interfaces/api/image'
import { captureException } from '@services/exceptions/capture-exception'

const FormMultiImageField = ({ label, name, required = false, value }: FormMultiImageFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'actions' })

  const [imagesPreview, setImagesPreview] = useState<string[]>([])
  const [files, setFiles] = useState<File[]>([])
  const [images, setImages] = useState<Image[]>([])
  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    if (value) {
      setImages(value)
      setImagesPreview([])
    }
  }, [value])

  const handleChange = (event) => {
    const newFiles: File[] = Array.from(event.currentTarget.files)
    const updatedFiles = [...files, ...newFiles]
    const currentImages = images ?? []
    setFieldValue(name, [...currentImages, ...updatedFiles]).catch(captureException)

    const newFilesUrls = newFiles.map(file => URL.createObjectURL(file))
    setImagesPreview([...imagesPreview, ...newFilesUrls])
    setFiles(updatedFiles)
  }

  const removeFile = (index) => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    const allFiles = [...(images ?? []), ...newFiles]

    setFieldValue(name, allFiles).catch(captureException)

    const newImagesPreview = [...imagesPreview]
    newImagesPreview.splice(index, 1)
    setImagesPreview(newImagesPreview)
    setFiles(newFiles)
  }

  const removeExistingImage = (image: Image) => {
    if (images) {
      const filteredImages = images.filter(existingImage => existingImage !== image)
      const allFiles = [...filteredImages, ...files]
      setFieldValue(name, allFiles).catch(captureException)
      setImages(filteredImages)
    }
  }

  return (
    <div className='flex flex-col order-1'>
      <label className='block font-medium text-gray-700 text-sm mb-2' htmlFor={name}>{label}</label>

      <div className='flex flex-col space-y-4'>
        <div className='flex space-x-4'>
          {images?.map((existingImage, index) => (
            <div className='relative h-32 rounded-lg overflow-hidden bg-gray-200 flex justify-between group' key={index}>
              <img alt={`preview ${index}`} className='w-full h-full object-cover rounded-md object-center' src={existingImage.url} />

              <button className='p-1.5 bg-white rounded-full absolute top-3 right-3 hover:bg-red-500 hover:fill-white'
                onClick={() => {
                  removeExistingImage(existingImage)
                }} type='button'
              >
                <TrashIcon className='w-3 h-3' />
              </button>
            </div>
          ))}
        </div>

        <div className='relative border-2 border-gray-100 rounded-lg placeholder-gray-400 focus:outline-none flex justify-between px-3 py-6 min-h-32 group'>
          <div className='flex flex-col flex-1'>
            <div className='flex flex-col items-center justify-center'>
              <input className='absolute opacity-0 w-full h-full top-0 left-0 cursor-pointer' multiple name={name} onChange={handleChange} ref={inputRef} required={required} type='file' />

              <div className='w-full flex flex-col gap-1 items-center justify-center text-center'>
                <ArrowDownOnSquareIcon className='w-6 h-6 m-auto' />

                <span className='font-medium'>{translateResource('uploadImages')}</span>

                <span className='text-gray-500 text-xs'>{translateResource('uploadImagesDescription')}</span>
              </div>
            </div>

            <div className='flex space-x-4 mt-4'>
              {imagesPreview.map((imagePreview, index) => (
                <div className='relative h-32 rounded-lg overflow-hidden bg-gray-200 flex justify-between group' key={index}>
                  <img alt={`preview ${index}`} className='w-full h-full object-cover rounded-md object-center' src={imagePreview} />

                  <button className='p-1.5 bg-white rounded-full absolute top-3 right-3 hover:bg-red-500 hover:fill-white'
                    onClick={() => {
                      removeFile(index)
                    }} type='button'
                  >
                    <TrashIcon className='w-3 h-3' />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormMultiImageField
