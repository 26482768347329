export const formatPrice = ({
  currency = '€',
  fixedValue = 2,
  price = 0,
  withCurrency = true
}) => {
  if (!price) {
    return 0
  }

  const formattedValue = (price / 100).toFixed(fixedValue || 0)

  return withCurrency ? `${formattedValue} ${currency}` : `${formattedValue}`
}
