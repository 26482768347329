import * as Yup from 'yup'

export const OfferSchema = Yup.object().shape({
  externalPrices: Yup.array().of(
    Yup.object().shape({
      image: Yup.string()
        .required('L\'image est requise'),
      platform: Yup.string()
        .required('La plateforme est requise'),
      price: Yup.number()
        .required('Le prix est requis')
        .min(0.01, 'Le prix doit être supérieur à 0')
    })
  ).min(1, 'Au moins un prix externe est requis'),
  label: Yup.string().required('Le label est requis'),
  manualRooms: Yup.array().when(['manualMode'], {
    is: (manualMode: boolean) => manualMode,
    then: () => Yup.array().of(
      Yup.object().shape({
        rates: Yup.array().of(
          Yup.object().shape({
            minimumMargin: Yup.number()
              .required('La marge minimum est requise')
              .min(0, 'La marge minimum doit être supérieure ou égale à 0'),
            purchasePrice: Yup.number()
              .required('Le prix d\'achat est requis')
              .min(0.01, 'Le prix d\'achat doit être supérieur à 0'),
            sellingPrice: Yup.number()
              .required('Le prix de vente est requis')
              .test('is-greater', 'Le prix de vente doit être supérieur au prix d\'achat + marge minimum', function (value) {
                const { minimumMargin, purchasePrice } = this.parent

                return value > (purchasePrice + minimumMargin)
              })
          })
        ).min(1, 'Au moins une option est requise')
      })
    ).min(1, 'Au moins une chambre est requise')
  }),
  rooms: Yup.array().of(
    Yup.object().shape({
      rates: Yup.array().of(
        Yup.object().shape({
          margin: Yup.number()
            .required('La marge est requise')
            .min(0, 'La marge doit être supérieure ou égale à 0'),
          minimumMargin: Yup.number()
            .required('La marge minimum est requise')
            .min(0, 'La marge minimum doit être supérieure ou égale à 0'),
          sellingPrice: Yup.number()
            .required('Le prix de vente est requis')
            .test('is-greater', 'Le prix de vente doit être supérieur au prix d\'achat + marge minimum', function (value) {
              const { minimumMargin, net } = this.parent

              return value > (minimumMargin + net / 100)
            })
        })
      )
    })
  )
})
