/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import {
  type RelationFieldsInterfaces
} from '@components/api-resource/api-resource-form/form-fields/relation-fields/relation-fields.interfaces'
import { fieldIsHidden, MEDIA_FIELDS } from '@components/api-resource/api-resource-form/form-utils/fields'
import FormAutocompleteField from '@components/form-fields/form-autocomplete-field'
import FormSearchField from '@components/form-fields/form-search-field'
import type { ApiPlatformEntity } from '@interfaces/api'
import SearchMap from '@services/api/definition/search-map'
import { isPropertyType } from '@services/tools/api-resources/property-types'

const RelationFields = <T extends ApiPlatformEntity>({
  data,
  definition,
  excludedFields,
  fieldsToDisplay,
  fieldsToHide,
  formValues,
  requestFields
}: RelationFieldsInterfaces<T>) => {
  const { t } = useTranslation('apiResources')

  const fields: ReactElement[] = []

  requestFields.forEach((field, index) => {
    if (
      (
        (
          'type' in field.properties &&
          isPropertyType(field, 'string') &&
          field.properties.format === 'iri-reference'
        )
      ) &&
      !MEDIA_FIELDS.includes(field.name) &&
      !excludedFields?.includes(field.name)
    ) {
      if (
        (fieldIsHidden(field, fieldsToDisplay, fieldsToHide)) ||
        !(field.name in SearchMap)
      ) {
        return
      }

      const value = data?.[field.name] ? data[field.name] : ''

      if (field.name === 'category' || field.name === 'tariffGrid') {
        fields.push(
          <FormAutocompleteField
            emptyStateLabel={t([`emptyState.${field.name}`, 'emptyState.default'])}
            key={field.name}
            label={t([`labels.${definition.name}.${field.name}`, `labels.${field.name}`])}
            name={field.name}
            required={field.required ?? false}
            value={value}
          />
        )
      } else if (field.name === 'experience') {
        fields.push(
          <FormSearchField
            emptyStateLabel={t([`emptyState.${field.name}`, 'emptyState.default'])}
            indexName={'artwork'}
            key={field.name}
            label={t([`labels.${definition.name}.${field.name}`, `labels.${field.name}`])}
            name={field.name}
            required={field.required ?? false}
            value={value}
          />
        )
      } else {
        fields.push(
          <FormSearchField
            emptyStateLabel={t([`emptyState.${field.name}`, 'emptyState.default'])}
            key={field.name}
            label={t([`labels.${definition.name}.${field.name}`, `labels.${field.name}`])}
            name={field.name}
            required={field.required ?? false}
            value={value}
          />
        )
      }
    }
  })

  return (
    <>
      {fields}
    </>
  )
}

export default RelationFields
