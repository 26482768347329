import { CalendarDaysIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import ApiResource from '@components/api-resource'
import BookingRequestsListEntry from '@components/booking-requests-list-entry/booking-requests-list-entry'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layouts/layout/layout'
import SectionTitle from '@components/section-title/section-title'

const BookingRequestsList = () => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'booking-requests' })
  const navigate = useNavigate()

  const breadcrumbs = [
    { href: '', name: translateResource('title') }
  ]

  const onEntryClick = (booking) => {
    navigate(`/booking-requests/${booking.uid}`)
  }

  return (
    <Layout title={translateResource('title')}>
      <Guard>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle icon={CalendarDaysIcon} title={translateResource('title')} />

        <ApiResource
          createdAt={'desc'}
          customFieldsToDisplay={['state', 'user', 'hotel', 'adultCount', 'childCount', 'checkInDate', 'checkOutDate', 'expiresAt', 'createdAt']}
          entryComponent={BookingRequestsListEntry}
          name={'booking-requests'}
          onClick={onEntryClick}
        />
      </Guard>
    </Layout>
  )
}

export default BookingRequestsList
