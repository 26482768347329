import { type OpenAPIV3_1 } from 'openapi-types'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { type TableProps } from '@components/api-resource/api-resource-lists/api-resource-table/api-resource-table.interfaces'
import InputCyclingSelect from '@components/api-resource/api-resource-lists/input-cycling-select'
import Pagination from '@components/api-resource/api-resource-lists/pagination/pagination'
import SkeletonLoaderTable from '@components/skeleton-loader/skeleton-loader-table'
import clsxm from '@services/tools/clsxm'

const Table = ({ children, definitionName, hasMargin = true, headers, isLoading, itemsPerPage, ordering, pageIndex, pagination, searchParams, setItemsPerPage, setPageIndex, setSearchParams, totalItems }: TableProps) => {
  const { t } = useTranslation('apiResources')
  const onOrderingChange = (orderingDefinition: OpenAPIV3_1.ParameterObject) => (newValue: string) => {
    setSearchParams({
      [orderingDefinition.name]: newValue
    })
  }

  const headersComponents: ReactElement[] = []
  headers.forEach(header => {
    // If column can be ordered, add control
    const orderingDefinition = ordering?.find(parameter => parameter.name.includes(header))
    let orderingControl = <></>
    if (orderingDefinition) {
      const schema = (orderingDefinition?.schema as OpenAPIV3_1.SchemaObject)
      if (schema.enum) {
        const values = schema.enum.map((entry: string) => ({
          key: entry,
          label: t(`filters.${entry}`)
        }))
        const currentKey = searchParams.get(orderingDefinition.name) ?? ''
        const key = currentKey !== '' ? currentKey : values[0].key
        const currentValue = { key, label: t(`filters.${key}`) }
        orderingControl = (
          <InputCyclingSelect
            currentValue={currentValue}
            onChange={onOrderingChange(orderingDefinition)}
            values={values}
          />
        )
      }
    }

    // Display column header
    headersComponents.push(
      <th className={`text-left ${orderingDefinition && orderingControl ? 'p-0 px-1' : 'p-4'}`} key={header}>
        <div className='flex items-start'>
          <div className={`flex items-center justify-between gap-2 font-medium ${orderingDefinition && orderingControl ? 'p-3' : 'p-0'}`}>
            {t([`colsLabels.${header}`, `labels.${header}`, `labels.${definitionName}.${header}`])}

            {orderingDefinition && orderingControl}
          </div>
        </div>
      </th>
    )
  })

  return (
    <div className={clsxm('overflow-x-auto scrollbar-hide w-full sm:w-auto md:w-full', { 'pl-4 pr-2 sm:pr:4 sm:pl-6 md:pr-6 md:pl-8 mb-8': hasMargin }) }>
      {!!pagination && (
        <Pagination
          isTop
          itemsPerPage={itemsPerPage}
          pageIndex={pageIndex}
          pagination={pagination}
          setItemsPerPage={setItemsPerPage}
          setPageIndex={setPageIndex}
          totalItems={totalItems}
        />
      )}

      <div className={clsxm('shadow shadow-gray-200 border-1 border-gray-50 sm:rounded-lg', { 'my-4': hasMargin && !pagination })}>
        <table className='min-w-full divide-y'>
          <thead className='bg-gray-50'>
            <tr className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
              {headersComponents}
            </tr>
          </thead>

          <tbody>
            {isLoading
              ? (
                <SkeletonLoaderTable headers={headers} />
              )
              : children}
          </tbody>
        </table>
      </div>

      {!!pagination && (
        <Pagination
          itemsPerPage={itemsPerPage}
          pageIndex={pageIndex}
          pagination={pagination}
          setItemsPerPage={setItemsPerPage}
          setPageIndex={setPageIndex}
          totalItems={totalItems}
        />
      )}
    </div>
  )
}

export default Table
