import { Bars3CenterLeftIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'

import Changelog from '@components/changelog/changelog'
import { type LayoutInterfaces } from '@components/layouts/layout/layout.interfaces'
import Notification from '@components/notification/notification'
import Sidebar from '@components/sidebar/sidebar'

export default function Layout ({ children, title }: LayoutInterfaces) {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <>
      <Helmet>
        <title>{title ?? 'Captain Booking'}</title>
      </Helmet>

      <div className='flex h-full w-full min-h-screen'>
        <Sidebar open={sidebarOpen} setOpen={setSidebarOpen} />

        <div className='flex flex-col flex-1 overflow-auto'>
          <div className='md:relative z-10 flex-shrink-0 flex border-gray-200 fixed lg:border-none md:hidden'>
            <button
              className='p-2 border rounded-br-md bg-gray-900 text-slate-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary lg:hidden'
              onClick={() => {
                setSidebarOpen(true)
              }}
              type='button'
            >
              <span className='sr-only'>Open sidebar</span>

              <Bars3CenterLeftIcon aria-hidden='true' className='h-6 w-6 text-gray-300' />
            </button>
          </div>

          <main className='flex-1 tracking-wide relative bg-gray-100'>
            <div className='mt-14 md:mt-6'>
              <div className='mx-auto'>
                {children}
              </div>
            </div>
          </main>

          <Notification />

          <Changelog />
        </div>
      </div>
    </>
  )
}
