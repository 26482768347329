import React from 'react'

import { BookingRequestInfos } from '@components/booking-request-infos'
import { type BookingRequestLayoutInterfaces } from '@pages/booking-requests/components/booking-request-layout'
import { BookingRequestHeader } from '@pages/booking-requests/entry/booking-request-header'

const BookingRequestLayout = ({ bookingRequest, children, offers }: BookingRequestLayoutInterfaces) => {
  return (
    <div className='mx-auto px-4 sm:px-6 lg:px-8'>

      <BookingRequestHeader bookingRequest={bookingRequest} offers={offers} />

      <div className='grid grid-cols-4 gap-4'>

        <div className='col-span-3 space-y-4'>
          <div className='bg-white overflow-hidden rounded-lg border border-gray-200 shadow-sm'>
            {children}
          </div>
        </div>

        <div>
          <div className='bg-white rounded-lg border border-gray-200 shadow-sm p-4 text-sm space-y-6'>
            <BookingRequestInfos bookingRequest={bookingRequest} />
          </div>
        </div>

      </div>
    </div>
  )
}

export default BookingRequestLayout
