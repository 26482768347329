import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { ErrorMessage, type FormikErrors } from 'formik'
import { useRef, useState } from 'react'

import Badge from '@components/badge'
import Button from '@components/buttons/button'
import IconButton from '@components/buttons/icon-button'
import FormNumberField from '@components/form-fields/form-number-field'
import { type AvailabilityRoom } from '@interfaces/api/availability'
import { type Board } from '@interfaces/api/board'
import { type BaseRoom } from '@interfaces/api/hotel-room'
import { type OfferRoomOptionsInterfaces } from '@pages/booking-requests/components/offer-room-options'
import AddRoomModal from '@pages/booking-requests/entry/add-room-modal/add-room-modal'
import AddRoomOptionModal from '@pages/booking-requests/entry/add-room-option-modal/add-room-option-modal'
import AvailabilitiesModal from '@pages/booking-requests/entry/availabilities-modal/availabilities-modal'
import { type OfferManualRoomValues } from '@root/interfaces/api/offer'
import { captureException } from '@services/exceptions/capture-exception'
import { capitalizeFirstLetter } from '@services/tools/string'

const OfferRoomOptions = ({ bookingRequest, errors, index, room, setFieldValue, values }: OfferRoomOptionsInterfaces) => {
  const setMarginTimeout = useRef<NodeJS.Timeout | null>(null)
  const [availabilitiesModalOpen, setAvailabilitiesModalOpen] = useState(false)
  const [addRoomModalOpen, setAddRoomModalOpen] = useState(false)
  const [addRoomOptionModalOpen, setAddRoomOptionModalOpen] = useState(false)
  const [selectedRoomNumber, setSelectedRoomNumber] = useState(1)

  const handleMarginChange = (setFieldValue, values, roomIndex, rateIndex) => (e) => {
    const newMargin = Number(e.target.value)
    setFieldValue(`rooms[${roomIndex}].rates[${rateIndex}].margin`, newMargin).catch(captureException)
    const newSellingPrice = Math.round((Number(values.rooms[roomIndex].rates[rateIndex].net) + Number(values.rooms[roomIndex].rates[rateIndex].net) * (newMargin / 100)))
    setFieldValue(`rooms[${roomIndex}].rates[${rateIndex}].sellingPrice`, newSellingPrice).catch(captureException)
  }

  const handleSellingPriceChange = (setFieldValue, values, roomIndex, rateIndex) => (e) => {
    const newSellingPrice = Number(e.target.value)
    setFieldValue(`rooms[${roomIndex}].rates[${rateIndex}].sellingPrice`, newSellingPrice).catch(captureException)
    if (setMarginTimeout.current !== null) {
      clearTimeout(setMarginTimeout.current)
    }
    setMarginTimeout.current = setTimeout(() => {
      const newMargin = Math.round(((Number(newSellingPrice) - Number(values.rooms[roomIndex].rates[rateIndex].net)) / Number(values.rooms[roomIndex].rates[rateIndex].net)) * 100)
      setFieldValue(`rooms[${roomIndex}].rates[${rateIndex}].margin`, newMargin).catch(captureException)
    }, 500)
  }

  const handleMinimumMarginChange = (setFieldValue, values, roomIndex, rateIndex) => (e) => {
    const newMinimumMargin = Number(e.target.value)
    setFieldValue(`rooms[${roomIndex}].rates[${rateIndex}].minimumMargin`, newMinimumMargin).catch(captureException)
  }

  const handleOpenAvailabilitiesModal = (roomNumber: number) => {
    setSelectedRoomNumber(roomNumber)
    setAvailabilitiesModalOpen(true)
  }

  const onAddRoomClick = () => {
    setAddRoomModalOpen(true)
  }

  const onAddRoomOptionClick = () => {
    setAddRoomOptionModalOpen(true)
  }

  const handleRemoveRoom = (roomIndex, setFieldValue, values, isManual = false) => {
    if (isManual) {
      const updatedRooms = [...values.manualRooms]
      updatedRooms[roomIndex] = {
        ...updatedRooms[roomIndex],
        rates: [],
        room: null
      }
      setFieldValue('manualRooms', updatedRooms).catch(captureException)
    } else {
      const updatedRooms = [...values.rooms]
      updatedRooms[roomIndex].room = null
      updatedRooms[roomIndex].rates = []
      setFieldValue('rooms', updatedRooms).catch(captureException)
    }
  }

  const handleRemoveRate = (roomIndex, rateIndex, setFieldValue, values, isManual = false) => {
    if (isManual) {
      const updatedRooms = [...values.manualRooms]
      updatedRooms[roomIndex].rates = updatedRooms[roomIndex].rates.filter((_, index) => index !== rateIndex)
      setFieldValue('manualRooms', updatedRooms).catch(captureException)
    } else {
      const updatedRooms = [...values.rooms]
      updatedRooms[roomIndex].rates = updatedRooms[roomIndex].rates.filter((_, index) => index !== rateIndex)
      setFieldValue('rooms', updatedRooms).catch(captureException)
    }
  }

  const handleAddRate = (rate, room: AvailabilityRoom, setFieldValue, values) => {
    const newRooms = [...values.rooms]
    const selectedRoom = newRooms[selectedRoomNumber - 1]

    // Ajouter le nouveau rate avec les marges par défaut et le prix en euros
    const updatedRates = [...selectedRoom.rates, {
      ...rate,
      margin: 10,
      minimumMargin: 20,
      net: rate.net / 100
    }]
    newRooms[selectedRoomNumber - 1] = { ...selectedRoom, rates: updatedRates, room }

    // Mettre à jour les valeurs du formulaire
    setFieldValue('rooms', newRooms).catch(captureException)

    // Mettre à jour le selling price pour le nouveau rate ajouté (en euros)
    const rateIndex = updatedRates.length - 1
    const newSellingPrice = Math.round(rate.net / 100 + (rate.net / 100) * (10 / 100))
    setFieldValue(`rooms[${selectedRoomNumber - 1}].rates[${rateIndex}].sellingPrice`, newSellingPrice).catch(captureException)
  }

  const handlePurchasePriceChange = (setFieldValue, values, roomIndex, rateIndex) => (e) => {
    const newPurchasePrice = Number(e.target.value)
    const currentRate = values.manualRooms[roomIndex].rates[rateIndex]
    const margin = currentRate.margin
    const newSellingPrice = Math.round(newPurchasePrice + (newPurchasePrice * (margin / 100)))

    setFieldValue(`manualRooms[${roomIndex}].rates[${rateIndex}].purchasePrice`, newPurchasePrice).catch(captureException)
    setFieldValue(`manualRooms[${roomIndex}].rates[${rateIndex}].sellingPrice`, newSellingPrice).catch(captureException)
  }

  const handleAddOption = (roomBoard: Board) => {
    const newRooms = [...values.manualRooms]
    const selectedRoom = newRooms[selectedRoomNumber - 1]

    // Ajouter le nouveau rate avec les marges par défaut et le prix en euros
    const updatedRates = [...selectedRoom.rates, {
      margin: 10,
      minimumMargin: 20,
      purchasePrice: 0,
      roomBoard,
      sellingPrice: 0
    }]
    newRooms[selectedRoomNumber - 1] = { ...selectedRoom, rates: updatedRates }

    // Mettre à jour les valeurs du formulaire
    setFieldValue('manualRooms', newRooms).catch(captureException)
  }

  const handleAddRoom = (room: BaseRoom) => {
    const newRooms = [...values.manualRooms]
    newRooms[selectedRoomNumber - 1] = { rates: [], room }
    setFieldValue('manualRooms', newRooms).catch(captureException)
  }

  return (
    <div className='mt-10' key={index}>

      <div className='flex items-center justify-between space-x-2 px-4 py-3 bg-slate-400' key={index}>
        <div className='font-medium'>
          {`Room ${index + 1}`}
        </div>

        <div className='space-x-2'>
          {room.paxes.map((pax, paxIndex) => (
            <Badge key={paxIndex}
              text={`${pax.type} ${pax.age ? ` - ${pax.age} an${pax.age > 1 ? 's' : ''}` : ''}`}
            />
          ))}
        </div>
      </div>

      {values.manualMode && (
        <>
          {values.manualRooms[index]?.room && (
            <div className='flex justify-between bg-slate-300 px-4 py-2'>
              <div className=''>
                {`${values.manualRooms[index]?.room?.description.fr ?? values.manualRooms[index]?.room?.description.en} - ${values.manualRooms[index]?.room?.code}`}
              </div>

              <IconButton
                icon={TrashIcon}
                onClick={() => {
                  handleRemoveRoom(index, setFieldValue, values, true)
                }}
              />
            </div>
          )}

          {values.manualRooms[index]?.room && values.manualRooms[index]?.rates?.map((rate, rateIndex) => (
            <div className='border p-4' key={rateIndex}>
              <div className='flex justify-between'>
                <p className='font-bold'>
                  {capitalizeFirstLetter(rate.roomBoard.description.fr !== '' ? rate.roomBoard.description.fr : rate.roomBoard.description.en)}
                </p>

                <IconButton
                  icon={TrashIcon}
                  onClick={() => {
                    handleRemoveRate(index, rateIndex, setFieldValue, values, true)
                  }}
                />
              </div>

              <div className='py-5 grid grid-cols-4 gap-4'>
                <FormNumberField
                  label="Prix d'achat"
                  name={`manualRooms[${index}].rates[${rateIndex}].purchasePrice`}
                  onChange={handlePurchasePriceChange(setFieldValue, values, index, rateIndex)}
                  required
                  unitComponent={(
                    <div
                      className='border border-gray-300 py-1.5 px-3 text-left text-gray-500 bg-gray-100 border-l-0 rounded-r-md'
                    >
                      {'€'}
                    </div>
                  )}
                  zeroAllowed
                />

                <FormNumberField
                  label='Marge minimum'
                  name={`manualRooms[${index}].rates[${rateIndex}].minimumMargin`}
                  onChange={handleMinimumMarginChange(setFieldValue, values, index, rateIndex)}
                  required
                  unitComponent={(
                    <div
                      className='border border-gray-300 py-1.5 px-3 text-left text-gray-500 bg-gray-100 border-l-0 rounded-r-md'
                    >
                      {'€'}
                    </div>
                  )}
                  zeroAllowed
                />

                <FormNumberField
                  label='Marge'
                  name={`manualRooms[${index}].rates[${rateIndex}].margin`}
                  onChange={handleMarginChange(setFieldValue, values, index, rateIndex)}
                  unitComponent={(
                    <div
                      className='border border-gray-300 py-1.5 px-3 text-left text-gray-500 bg-gray-100 border-l-0 rounded-r-md'
                    >
                      {'%'}
                    </div>
                  )}
                  zeroAllowed
                />

                <FormNumberField
                  label='Prix de vente'
                  name={`manualRooms[${index}].rates[${rateIndex}].sellingPrice`}
                  onChange={handleSellingPriceChange(setFieldValue, values, index, rateIndex)}
                  required
                  unitComponent={(
                    <div
                      className='border border-gray-300 py-1.5 px-3 text-left text-gray-500 bg-gray-100 border-l-0 rounded-r-md'
                    >
                      {'€'}
                    </div>
                  )}
                  zeroAllowed
                />
              </div>
            </div>
          ))}
        </>
      )}

      {!values.manualMode && (
        <>
          {values.rooms[index].room && (
            <div className='flex justify-between bg-slate-300 px-4 py-2'>
              <div className=''>
                {values.rooms[index].room?.description.en}
              </div>

              <IconButton
                icon={TrashIcon}
                onClick={() => {
                  handleRemoveRoom(index, setFieldValue, values, false)
                }}
              />
            </div>
          )}

          {values.rooms[index]?.rates.map((rate, rateIndex) => (
            <div className='border p-4' key={rateIndex}>
              <div className='flex justify-between'>
                <p className='font-bold'>
                  {rate.boardName}
                </p>

                <IconButton
                  icon={TrashIcon}
                  onClick={() => {
                    handleRemoveRate(index, rateIndex, setFieldValue, values)
                  }}
                />
              </div>

              <div className='py-5 grid grid-cols-4 gap-4'>
                <div className='flex flex-col text-gray-900 gap-2'>
                  <div className='block font-medium text-sm'>Prix d'achat</div>

                  <div className='flex'>
                    <div className='rounded-l-lg text-white text-center border border-gray-400 bg-gray-400 px-3 py-1.5 cursor-not-allowed flex-1'>
                      {rate.net}
                    </div>

                    <div className='border border-gray-400 py-1.5 px-3 text-left text-gray-500 bg-gray-100 border-l-0 rounded-r-md'>
                      {'€'}
                    </div>
                  </div>
                </div>

                <FormNumberField
                  label='Marge minimum'
                  name={`rooms[${index}].rates[${rateIndex}].minimumMargin`}
                  onChange={handleMinimumMarginChange(setFieldValue, values, index, rateIndex)}
                  required
                  unitComponent={(
                    <div
                      className='border border-gray-300 py-1.5 px-3 text-left text-gray-500 bg-gray-100 border-l-0 rounded-r-md'
                    >
                      {'€'}
                    </div>
                  )}
                  zeroAllowed
                />

                <FormNumberField
                  label='Marge'
                  name={`rooms[${index}].rates[${rateIndex}].margin`}
                  onChange={handleMarginChange(setFieldValue, values, index, rateIndex)}
                  unitComponent={(
                    <div
                      className='border border-gray-300 py-1.5 px-3 text-left text-gray-500 bg-gray-100 border-l-0 rounded-r-md'
                    >
                      {'%'}
                    </div>
                  )}
                  zeroAllowed
                />

                <FormNumberField
                  label='Prix de vente'
                  name={`rooms[${index}].rates[${rateIndex}].sellingPrice`}
                  onChange={handleSellingPriceChange(setFieldValue, values, index, rateIndex)}
                  required
                  unitComponent={(
                    <div
                      className='border border-gray-300 py-1.5 px-3 text-left text-gray-500 bg-gray-100 border-l-0 rounded-r-md'
                    >
                      {'€'}
                    </div>
                  )}
                  zeroAllowed
                />
              </div>
            </div>
          ))}
        </>
      )}

      <div className='flex flex-col items-end justify-end my-4 px-4'>
        {!values.manualMode && (
          <Button
            icon={PlusIcon}
            onClick={() => {
              handleOpenAvailabilitiesModal(index + 1)
            }}
            size='small'
            style='secondary'
            type='button'
          >
            Ajouter une option
          </Button>
        )}

        {values.manualMode && !values.manualRooms[index].room && (
          <Button
            icon={PlusIcon}
            onClick={onAddRoomClick}
            size='small'
            style='secondary'
            type='button'
          >
            Ajouter une chambre
          </Button>
        )}

        {values.manualMode && values.manualRooms[index].room && (
          <Button
            icon={PlusIcon}
            onClick={onAddRoomOptionClick}
            size='small'
            style='secondary'
            type='button'
          >
            Ajouter une option
          </Button>
        )}

        {errors && !Array.isArray((errors?.manualRooms?.[index] as FormikErrors<OfferManualRoomValues>)?.rates) && (
          <ErrorMessage className='px-4 mt-2 text-xs text-red-600 font-medium' component='div' name={`manualRooms[${index}].rates`} />
        )}
      </div>

      <AddRoomModal
        hotel={bookingRequest.hotel}
        onAddRoom={handleAddRoom}
        open={addRoomModalOpen}
        setOpen={setAddRoomModalOpen}
      />

      <AddRoomOptionModal
        hotel={bookingRequest.hotel}
        onAddOption={handleAddOption}
        open={addRoomOptionModalOpen}
        setOpen={setAddRoomOptionModalOpen}
      />

      <AvailabilitiesModal
        allowSelectHotel={false}
        bookingRequest={bookingRequest}
        onAddRate={(rate, room) => {
          handleAddRate(rate, room, setFieldValue, values)
        }}
        open={availabilitiesModalOpen}
        roomNumber={selectedRoomNumber}
        selectedRoom={values.rooms[selectedRoomNumber - 1]?.room}
        setOpen={setAvailabilitiesModalOpen}
      />
    </div>
  )
}

export default OfferRoomOptions
