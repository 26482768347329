import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions, Label
} from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { ErrorMessage, Field, useField } from 'formik'
import { useEffect, useState } from 'react'

import { type FormSearchFieldProps } from '@components/form-fields/form-search-field/form-search-field-interfaces'
import { type MeiliSearchArticle } from '@interfaces/api/meili/query'
import useMeiliSearchQuery from '@services/api/resources/meili-query'
import { captureException } from '@services/exceptions/capture-exception'
import clsxm from '@services/tools/clsxm'

const FormSearchField = ({ emptyStateLabel, indexName, label, name, required = false, value }: FormSearchFieldProps) => {
  const [field, , { setValue }] = useField({ name })
  const [query, setQuery] = useState('')
  const [selectedItem, setSelectedItem] = useState<MeiliSearchArticle>()

  const environmentPrefix = process.env.NODE_ENV === 'production' ? 'app_' : 'app_dev_'
  const indexUid = `${environmentPrefix}${indexName ?? name}`

  const { data } = useMeiliSearchQuery({
    indexUid,
    q: query
  })

  const items = data?.hits ?? []

  // On selected item update
  useEffect(() => {
    if (selectedItem?.uid) {
      // We set value like this because selectedItem come from meili and doesnt have @id
      setValue(selectedItem).catch(captureException)
    } else if (!required) {
      setValue('').catch(captureException)
    }
  }, [selectedItem, setValue])

  // On init
  useEffect(() => {
    // Default value setup
    if (value && !selectedItem) {
      setSelectedItem(value)
    }
  }, [])

  // On init when items fetched
  useEffect(() => {
    // If no value and required we set first item as value
    if (required && !value && items[0] && !field.value) {
      setSelectedItem(items[0])
      // We set value like this because items[0] come from meili and doesnt have @id
      setValue(items[0]).catch(captureException)
    }
  }, [items])

  // Use effect to handle data update when navigating
  useEffect(() => {
    if (selectedItem && value?.['@id'] && value?.['@id'] !== selectedItem['@id']) {
      setSelectedItem(value)
      setValue(value).catch(captureException)
    }
    if (value?.['@id'] && !selectedItem) {
      setSelectedItem(value)
    }
    if (!value && selectedItem) {
      setSelectedItem(undefined)
    }
  }, [value])

  const getDisplayName = (item) => {
    if (item === null) {
      return ''
    }

    return item.name?.fr || item.name || item.fullName || item.label || item.title || ''
  }

  return (
    <div className='flex flex-col'>
      <Field name={name}>
        {() => (
          <Combobox as='div' onChange={(value) => {
            setSelectedItem(value)
            setValue(value).catch(captureException)
          }} value={selectedItem}
          >
            {label && (
              <Label className='block font-medium text-gray-700 text-sm'>
                {label}

                {required && <sup className='text-gray-500 font-normal'>*</sup>}
              </Label>
            )}

            <div className='relative mt-2'>
              <ComboboxInput
                className='appearance-none w-full rounded-md bg-gray-50 border border-gray-300 py-1.5 pl-3 pr-10 text-gray-900 shadow-sm sm:text-sm sm:leading-6 focus:outline-none'
                displayValue={(item) => getDisplayName(item)}
                name={name}
                onChange={(event) => {
                  setQuery(event.target.value)
                }}
              />

              {items.length > 0 && (
                <ComboboxOptions className='absolute z-10 w-full mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm'>
                  {!required && (
                    <ComboboxOption className={({ focus }) => clsxm('relative cursor-default select-none py-2 pl-3 pr-9', focus ? 'bg-slate-900 text-white' : 'text-gray-900')} value={null}>
                      {({ focus }) => (
                        <span className={clsxm('block truncate', focus && 'font-semibold')}>{emptyStateLabel ?? '- Select -'}</span>
                      )}
                    </ComboboxOption>
                  )}

                  {items.map((item) => (
                    <ComboboxOption className={({ focus }) => clsxm('relative cursor-default select-none py-2 pl-3 pr-9', focus ? 'bg-slate-900 text-white' : 'text-gray-900')} key={item.uid} value={item}>
                      {({ focus, selected }) => (
                        <>
                          <span className={clsxm('block truncate', selected && 'font-semibold')}>{getDisplayName(item)}</span>
                          {selected && (
                            <span className={clsxm('absolute inset-y-0 right-0 flex items-center pr-4', focus ? 'bg-slate-900 text-white' : 'text-primary')}>
                              <CheckIcon aria-hidden='true' className='h-5 w-5' />
                            </span>
                          )}
                        </>
                      )}
                    </ComboboxOption>
                  ))}
                </ComboboxOptions>
              )}
            </div>
          </Combobox>
        )}
      </Field>

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormSearchField
