import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import { type HotelRoom } from '@interfaces/api/hotel-room'
import { type AddRoomModalInterfaces } from '@pages/booking-requests/entry/add-room-modal/add-room-modal.interfaces'
import useItemChildrenQuery from '@services/api/resources/item-children'
import { capitalizeFirstLetter } from '@services/tools/string'

const AddRoomModal = ({ hotel, onAddRoom, open, setOpen }: AddRoomModalInterfaces) => {
  const onRoomSelect = (room: HotelRoom) => {
    onAddRoom(room.room)
    setOpen(false)
  }

  const {
    data: {
      data: rooms
    } = {}
  } = useItemChildrenQuery<HotelRoom>({
    itemId: hotel.uid,
    path: 'hotels/{uid}/rooms'
  })

  return (
    <Dialog
      className='relative z-10'
      onClose={() => {
        setOpen(false)
      }}
      open={open}
    >
      <DialogBackdrop
        className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out'
        transition
      />

      <div className='fixed inset-0 overflow-hidden'>
        <div className='absolute inset-0 overflow-hidden'>
          <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
            <DialogPanel
              className='pointer-events-auto w-screen max-w-xl transform transition duration-500 ease-in-out'
              transition
            >
              <div className='flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl'>
                <div className='px-4 sm:px-6'>
                  <div className='flex items-start justify-between'>
                    <DialogTitle className='text-base font-semibold leading-6 text-gray-900'>
                      Ajouter une chambre
                    </DialogTitle>

                    <div className='ml-3 flex h-7 items-center'>
                      <button
                        className='relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                        onClick={() => {
                          setOpen(false)
                        }}
                        type='button'
                      >
                        <span className='absolute -inset-2.5' />

                        <span className='sr-only'>Fermer</span>

                        <XMarkIcon aria-hidden='true' className='h-6 w-6' />
                      </button>
                    </div>
                  </div>
                </div>

                <div className='relative mt-6 flex-1 px-4 sm:px-6'>
                  <div className='flex flex-col space-y-2'>
                    {rooms?.map((room, index) => (
                      <button
                        className='flex w-full rounded-lg border border-slate-200 p-2 justify-between items-center cursor-pointer whitespace-nowrap'
                        key={`${room.uid}-${index}`}
                        onClick={() => {
                          onRoomSelect(room)
                        }}
                        type='button'
                      >
                        {`${capitalizeFirstLetter(room.room.description.fr ?? room.room.description.en)} - ${room.room.code}`}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default AddRoomModal
