import { ErrorMessage, Field, useField } from 'formik'
import { useEffect } from 'react'

import { type FormNumberFieldProps } from '@components/form-fields/form-number-field/index'
import { captureException } from '@services/exceptions/capture-exception'
import clsxm from '@services/tools/clsxm'

const FormNumberField = ({ label, min = 0, name, onChange, required = false, step = 'any', unitComponent, zeroAllowed = false }: FormNumberFieldProps) => {
  const [input, , helpers] = useField({ name })
  const { setValue } = helpers
  const { value } = input

  useEffect(() => {
    if (value === 0 && !zeroAllowed) {
      setValue(1).catch(captureException)
    }
  }, [value])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValue(newValue).catch(captureException)
    onChange?.(event)
  }

  return (
    <div className='flex flex-col text-gray-900 gap-2'>
      {label && (
        <label className='block font-medium text-sm' htmlFor={name}>
          {label}

          {required && <sup className='text-gray-500 font-normal'>*</sup>}
        </label>
      )}

      <div className={clsxm('flex relative', { 'items-center': unitComponent })}>
        <Field
          className={clsxm(
            'text-gray-700 text-center border border-gray-300 bg-gray-50 appearance-none px-3 py-1.5 placeholder-gray-500 focus:outline-none',
            {
              'rounded-lg': !unitComponent,
              'w-full rounded-l-lg': unitComponent
            }
          )}
          min={min}
          name={name}
          onChange={handleChange}
          required={required}
          step={step}
          type='number'
        />

        {unitComponent && (
          unitComponent
        )}
      </div>

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormNumberField
