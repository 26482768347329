import { PaperAirplaneIcon } from '@heroicons/react/24/outline'
import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@components/buttons/button'
import Modal from '@components/modals/modal'
import { NotificationType } from '@components/notification/notification.interfaces'
import { BookingRequestState } from '@interfaces/api/booking-request/booking-request'
import { type BookingRequestHeaderInterfaces } from '@pages/booking-requests/entry/booking-request-header'
import BookingRequestStatusBadge
  from '@pages/booking-requests/entry/booking-request-status-badge/booking-request-status-badge'
import { getApiResourceItemQueryUrl } from '@services/api/resources/item-query'
import usePatchEntry from '@services/api/resources/patch-entry-query'
import { captureException } from '@services/exceptions/capture-exception'
import { useNotificationStore } from '@services/stores/notification/notification'

const BookingRequestHeader = ({ bookingRequest, offers }: BookingRequestHeaderInterfaces) => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'booking-requests' })
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const queryClient = useQueryClient()

  const [openProposeModal, setOpenProposeModal] = useState<boolean>(false)
  const [isLoadingPropose, setIsLoadingPropose] = useState<boolean>(false)

  const { mutateAsync: patchBookingPropose } = usePatchEntry({ id: bookingRequest.uid, path: 'booking-requests/{uid}/propose' })

  const { displayNotification } = useNotificationStore()

  const onProposeClick = () => {
    setOpenProposeModal(true)
  }

  const onProposeHandler = async () => {
    setIsLoadingPropose(true)
    await patchBookingPropose({}).then(() => {
      // TODO REFETCH BOOKING REQUEST
      queryClient.invalidateQueries({ queryKey: [getApiResourceItemQueryUrl('booking-requests/{uid}', bookingRequest.uid)] }).catch(captureException)

      setOpenProposeModal(false)
      setIsLoadingPropose(false)
      displayNotification(translateResource('message.propose.success.title'), translateResource('message.propose.success.description'), NotificationType.success)
    }).catch(captureException)
  }

  return (
    <div className='flex justify-between mb-4'>

      <div className='space-y-1'>
        <div className='flex items-center space-x-2'>
          <span className='font-semibold text-slate-700'>{bookingRequest.user.email}</span>

          <BookingRequestStatusBadge status={bookingRequest.state} type='state' />
        </div>

        <div className='text-sm font-light text-gray-500'>
          {bookingRequest.createdAt && (
            <time dateTime={bookingRequest.createdAt}>
              {`${moment(bookingRequest.createdAt).format('LL')} à ${moment(bookingRequest.createdAt).format('LT')}`}
            </time>
          )}
        </div>
      </div>

      <div className='flex items-center justify-center space-x-2'>
        {bookingRequest && bookingRequest.state === BookingRequestState.requested && offers && offers.length > 0 && (
          <Button icon={PaperAirplaneIcon} onClick={onProposeClick}>
            Envoyer les offres
          </Button>
        )}
      </div>

      <Modal center open={openProposeModal} setOpen={setOpenProposeModal}
        title={'Envoyer les offres'}
      >
        <div className='flex flex-col items-center p-8'>
          <div className='text-center text-xl whitespace-pre-line'>
            Êtes-vous sûr de vouloir envoyer les offres au client ?
            <br />
            Il recevra une notification.
          </div>

          <div className='flex mt-6 gap-8'>
            <Button isLoading={isLoadingPropose} onClick={onProposeHandler}
              style='warning'
            >
              {translateActions('confirm')}
            </Button>

            <Button disabled={isLoadingPropose} onClick={() => {
              setOpenProposeModal(false)
            }}
            >
              {translateActions('cancel')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default BookingRequestHeader
