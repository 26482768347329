export enum Currency {
  EUR = 'EUR'
}

export const convertCentsToEuros = (cents: number) => {
  const euros = (cents / 100).toFixed(2)

  return `${euros} €`
}

export const calculateDifference = (price: number, offerPrice: number) => {
  return Math.floor(((price - offerPrice) / offerPrice) * 100)
}

export const displayDifference = (price: number, offerPrice: number) => {
  const difference = calculateDifference(price, offerPrice)

  return difference >= 0 ? `+${difference}` : `${difference}`
}

export const calculateDifferenceClass = (price: number, offerPrice: number) => {
  const difference = calculateDifference(price, offerPrice)

  return difference >= 0 ? 'text-red-500' : 'text-green-500'
}
