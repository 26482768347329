import { type ApiPlatformEntity } from '@interfaces/api/api'
import { type AvailabilityRoom, type Rate } from '@interfaces/api/availability'
import { type Board } from '@interfaces/api/board'
import { type BaseRoom } from '@interfaces/api/hotel-room'

export enum BookingPlatform {
  HOTELBEDS = 'hotelbeds',
  RATEHAWK = 'ratehawk'
}

export enum ExternalPricePlatform {
  BOOKING = 'booking',
  EXPEDIA = 'expedia'
}

export interface ExternalPrice {
  image: string
  platform: ExternalPricePlatform
  price: number
}

export interface Offer extends ApiPlatformEntity {
  createdAt: string
  externalPrices: ExternalPrice[]
  label: string
  maximumSellingPrice: number
  minimumSellingPrice: number
  priority: number
  rooms: OfferRoom[]
  uid: string
  updatedAt: string
}

export interface OfferRoom {
  paxes: Array<{
    age: number
    type: string
  }>
  rates: OfferRate[]
  room: AvailabilityRoom | null
}

export interface OfferRate extends Rate {
  minimumMargin: number
  purchasePrice: number
  rateKey: string
  sellingPrice: number
}

export interface OfferManualRate {
  minimumMargin: number
  purchasePrice: number
  roomBoard: Board
  sellingPrice: number
}

export interface OfferPost {
  bookingRequest: string
}

export interface OfferCreateFormValues {
  externalPrices: ExternalPrice[]
  label: string
  manualMode: boolean
  manualRooms: OfferManualRoomValues[]
  platform?: string
  rooms: OfferRoomFormValues[]
}

export interface OfferManualRoomValues {
  rates: OfferManualRate[]
  room: BaseRoom | null
}

export interface OfferRoomFormValues {
  rates: OfferRate[]
  room: AvailabilityRoom | null
}

export interface OfferEditFormValues {
  externalPrices: ExternalPrice[]
  label: string
}
