import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import 'moment/locale/fr'

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Button from '@components/buttons/button'
import Guard from '@components/guard/guard'
import Layout from '@components/layouts/layout/layout'
import ModalConfirmDelete from '@components/modals/modal-confirm-delete/modal-confirm-delete'
import { type BookingRequest, BookingRequestState } from '@interfaces/api/booking-request/booking-request'
import { type Offer } from '@interfaces/api/offer'
import BookingRequestLayout from '@pages/booking-requests/components/booking-request-layout/booking-request-layout'
import OffersTable from '@pages/booking-requests/entry/offers-table/offers-table'
import useDeleteEntrySimple from '@services/api/resources/delete-entry-query-simple'
import useItemChildrenQuery from '@services/api/resources/item-children'
import useApiResourceItemQuery from '@services/api/resources/item-query'
import usePatchEntrySimple from '@services/api/resources/patch-entry-query-simple'
import { captureException } from '@services/exceptions/capture-exception'

const BookingEntry = () => {
  const { id = '' } = useParams()
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'booking-requests' })
  const navigate = useNavigate()

  const {
    data: bookingRequest,
    refetch: refetchBookingRequest
  } = useApiResourceItemQuery<BookingRequest>({ id, path: 'booking-requests/{uid}', refetchOnMount: 'always' })

  const breadcrumbs = [
    { href: '/booking-requests', name: translateResource('title') },
    { href: `/booking-requests/${bookingRequest?.uid}`, name: `Demande ${bookingRequest?.user?.email ?? ''}` }
  ]

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [offerToDelete, setOfferToDelete] = useState<string>('')
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false)

  const {
    data: {
      data: offers
    } = {},
    refetch: refetchOffers
  } = useItemChildrenQuery<Offer>({
    itemId: id,
    path: 'booking-requests/{uid}/offers'
  })

  const { mutateAsync: deleteOffer } = useDeleteEntrySimple()
  const { mutateAsync: patchBookingRequest } = usePatchEntrySimple({ path: 'booking-requests/{uid}' })

  const onAddOfferClick = () => {
    navigate(`/booking-requests/${id}/add-offer`)
  }

  const onEditOffer = (offer: Offer) => {
    navigate(`/booking-requests/${id}/offer/${offer.uid}`)
  }

  const onDeleteOffer = (uid: string) => {
    setOpenDeleteModal(true)
    setOfferToDelete(uid)
  }

  const handleDeleteOffer = () => {
    setIsLoadingDelete(true)
    deleteOffer({ id: offerToDelete, path: 'offers/{uid}' }).then(() => {
      setOpenDeleteModal(false)
      setIsLoadingDelete(false)
      refetchOffers().catch(captureException)
    }).catch(captureException)
  }

  const onToggleMain = async (offerId: string) => {
    await patchBookingRequest({
      data: {
        mainOffer: offerId
      },
      id
    })
    refetchBookingRequest().catch(captureException)
  }

  return (
    <Layout title={`${translateResource('title')} - ${bookingRequest?.user.email ?? ''}`}>
      <Guard>

        <Breadcrumbs breadcrumbs={breadcrumbs} />

        {bookingRequest && offers
          ? (
            <BookingRequestLayout bookingRequest={bookingRequest} offers={offers}>
              <div className='p-4'>
                {offers && offers.length > 0 && (
                  <OffersTable
                    mainOffer={bookingRequest.mainOffer}
                    offers={offers}
                    onDeleteOffer={onDeleteOffer}
                    onEditOffer={onEditOffer}
                    selectedOffer={bookingRequest.selectedOffer}
                    toggleMain={onToggleMain}
                  />
                )}

                {bookingRequest.state !== BookingRequestState.accepted && (
                  <div className='flex justify-end mt-6'>
                    <Button onClick={onAddOfferClick} style={'secondary'}>Ajouter une offre</Button>
                  </div>
                )}
              </div>
            </BookingRequestLayout>
          )
          : <></>}

        <ModalConfirmDelete
          isLoading={isLoadingDelete}
          message={'Êtes-vous sûr de vouloir supprimer cette offre ?'}
          onConfirm={handleDeleteOffer}
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          title={'Suppression d\'une offre'}
        />
      </Guard>
    </Layout>
  )
}

export default BookingEntry
