/* eslint-disable @typescript-eslint/ban-ts-comment */
import { AdvancedMarker, APIProvider, ControlPosition, Map, MapControl } from '@vis.gl/react-google-maps'
import { Field, useField } from 'formik'
import React, { useEffect, useState } from 'react'

import { type FormMapFieldProps } from '@components/form-fields/form-map-field/form-map-field-interfaces'
import MapAddress from '@components/form-fields/form-map-field/map-address'
import PlaceAutocompleteClassic from '@components/form-fields/form-map-field/map-autocomplete'
import { captureException } from '@services/exceptions/capture-exception'

export interface AutocompleteMode { id: string; label: string };

const FormMapField = ({ defaultCoordinates = { lat: 48.86110447419418, lng: 2.3353787398782515 }, label, name, required = false }: FormMapFieldProps) => {
  const [{ value }, , helpers] = useField({ name })
  const { setValue } = helpers

  const zoom = 11
  const [defaultCenter, setDefaultCenter] = useState(value ? { lat: parseFloat(value.latitude), lng: parseFloat(value.longitude) } : defaultCoordinates)
  const [markerPosition, setMarkerPosition] = useState(value ? { lat: parseFloat(value.latitude), lng: parseFloat(value.longitude) } : defaultCoordinates)

  useEffect(() => {
    const initialCoordinates = value ? { lat: parseFloat(value.latitude), lng: parseFloat(value.longitude) } : defaultCoordinates
    setDefaultCenter(initialCoordinates)
    setMarkerPosition(initialCoordinates)

    if (!value) {
      setValue({ latitude: initialCoordinates.lat.toString(), longitude: initialCoordinates.lng.toString() }).catch(captureException)
    }
  }, [])

  const autocompleteFinished = (place) => {
    const lng = place.geometry.location.lng()
    const lat = place.geometry.location.lat()
    setValue({ latitude: lat.toString(), longitude: lng.toString() }).catch(captureException)
    setMarkerPosition({ lat, lng })
  }

  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat()
    const lng = e.latLng.lng()
    setMarkerPosition({
      lat,
      lng
    })
    setValue({ latitude: lat.toString(), longitude: lng.toString() }).catch(captureException)
  }

  return (
    <div className='flex flex-col'>
      <label className='block font-medium text-gray-900 mb-2 text-sm' htmlFor={name}>{label}</label>

      <Field name={name} required={required} type='hidden' value={value || defaultCoordinates} />

      <div>

        <div className='h-[400px] w-full mb-4'>
          {process.env.REACT_APP_GOOGLE_API_KEY && (
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY ?? ''}>
              <MapAddress markerPosition={markerPosition} />

              <Map
                defaultCenter={{ lat: defaultCenter.lat, lng: defaultCenter.lng }}
                defaultZoom={zoom}
                disableDefaultUI={true}
                gestureHandling={'greedy'}
                mapId={'be980f18e15f6dac'}
              >
                <AdvancedMarker
                  draggable={true}
                  onDragEnd={onMarkerDragEnd}
                  position={markerPosition}
                />
              </Map>

              <MapControl position={ControlPosition.TOP}>
                <PlaceAutocompleteClassic onPlaceSelect={autocompleteFinished} />
              </MapControl>
            </APIProvider>
          )}
        </div>
      </div>

    </div>
  )
}

export default FormMapField
