import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { type GroupedFieldsInterfaces } from '@components/api-resource/api-resource-form/form-fields/grouped-fields'
import { fieldIsHidden } from '@components/api-resource/api-resource-form/form-utils/fields'
import type { ApiPlatformEntity } from '@interfaces/api'

const GroupedFields = <T extends ApiPlatformEntity>({
  data,
  definition,
  fieldsToDisplay,
  fieldsToHide,
  requestFields
}: GroupedFieldsInterfaces<T>) => {
  const { t } = useTranslation('apiResources')

  const fields: ReactElement[] = []

  // Créez un objet pour associer les noms de champs aux composants correspondants
  const fieldComponents = {}

  requestFields.forEach((field, index) => {
    if ('type' in field.properties) {
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      if (fieldIsHidden(field, fieldsToDisplay, fieldsToHide)) {

      }
    }
  })

  // Parcourez fieldsToDisplay si défini, sinon ajoutez les champs dans l'ordre d'origine
  if (fieldsToDisplay) {
    fieldsToDisplay.forEach((fieldName) => {
      if (fieldComponents[fieldName]) {
        fields.push(fieldComponents[fieldName])
      }
    })
  } else {
    Object.keys(fieldComponents).forEach((fieldName) => {
      fields.push(fieldComponents[fieldName])
    })
  }

  return (
    <>
      {fields}
    </>
  )
}

export default GroupedFields
