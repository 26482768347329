import { type UseMutationResult } from '@tanstack/react-query'
import { Form, Formik } from 'formik'
import * as React from 'react'
import * as Yup from 'yup'

import Badge from '@components/badge'
import Button from '@components/buttons/button'
import FormTextField from '@components/form-fields/form-text-field'
import { type ApiReponseError } from '@interfaces/api/api'
import ExternalPricesForm from '@pages/booking-requests/components/external-prices-form/external-prices-form'
import { type OfferEditFormInterfaces } from '@pages/booking-requests/components/offer-edit-form/offer-edit-form.interfaces'
import { formatPrice } from '@root/services/tools/price'
import { usePostFileQuery } from '@services/api/resources/post-entry-file'
import { handleImageViolations } from '@services/tools/api-resources/violations'

const OfferSchema = Yup.object().shape({
  externalPrices: Yup.array().min(1, 'Au moins un prix externe est requis'),
  label: Yup.string().required('Le label est requis')
})

const OfferEditForm = ({ offer, onSubmit }: OfferEditFormInterfaces) => {
  const { mutateAsync: uploadFileAsync } = usePostFileQuery('images')

  const initialValues = {
    externalPrices: offer.externalPrices.map(price => ({
      ...price,
      price: price.price / 100
    })),
    label: offer.label
  }

  const handleSubmit = async (values, formikHelpers) => {
    const { setErrors, setSubmitting } = formikHelpers
    try {
      const submissionValues = { ...values }

      // Traiter chaque prix externe pour uploader les images et convertir les prix
      for (let i = 0; i < submissionValues.externalPrices.length; i++) {
        const price = submissionValues.externalPrices[i]

        // Convertir le prix en nombre
        submissionValues.externalPrices[i] = {
          image: price.image['@id'],
          platform: price.platform,
          price: Number(price.price * 100)
        }

        // Gérer l'upload d'image si c'est un nouveau fichier
        if (!price.image['@id']) {
          const formData = new FormData()
          formData.append('file', price.image)

          try {
            const uploadedImageUrl = await uploadFileAsync(formData)
            submissionValues.externalPrices[i] = {
              ...submissionValues.externalPrices[i],
              image: uploadedImageUrl
            }
          } catch (e) {
            const err = e as UseMutationResult<ApiReponseError>
            const errorMessage = handleImageViolations(err?.data?.violations ?? [])
            setErrors({ [`externalPrices.${i}.image`]: errorMessage })

            return
          }
        }
      }

      onSubmit(submissionValues, formikHelpers)
    } catch (error) {
      console.error('Error during form submission:', error)
      setSubmitting(false)
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={OfferSchema}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form className='flex h-full flex-col'>
          <div className='flex-1'>
            <div className='bg-gray-50 px-4 py-6 sm:px-6'>
              <div className='flex items-start justify-between space-x-3'>
                <div className='space-y-1'>
                  <div className='text-base font-semibold leading-6 text-gray-900'>
                    {'Modifier une offre'}
                  </div>
                </div>
              </div>
            </div>

            <div className='space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0'>
              <div className='space-y-2 px-4 py-5'>
                <FormTextField label='Label' name='label' required />
              </div>

              <div className='px-4 py-5'>
                <ExternalPricesForm
                  externalPrices={values.externalPrices}
                />
              </div>

              <div className=''>
                {offer.rooms.map((offerRoom, index) => (
                  <div className='my-10' key={index}>
                    <div className='flex items-center justify-between space-x-2 px-4 py-3 bg-slate-400'>
                      <div className='font-medium'>
                        {`Room ${index + 1}`}
                      </div>

                      <div className='space-x-2'>
                        {offerRoom.paxes.map((pax, paxIndex) => (
                          <Badge key={paxIndex}
                            text={`${pax.type} ${pax.age ? ` - ${pax.age} an${pax.age > 1 ? 's' : ''}` : ''}`}
                          />
                        ))}
                      </div>
                    </div>

                    {offerRoom.room && (
                      <div className='bg-slate-300 px-4 py-2'>
                        {offerRoom.room?.description.en}
                      </div>
                    )}

                    {offerRoom.rates.map((rate, rateIndex) => (
                      <div className='border p-4' key={rateIndex}>
                        <p className='font-bold mb-4'>
                          {rate.boardName}
                        </p>

                        <div className='py-5 grid grid-cols-4 gap-4'>
                          <div className='flex flex-col text-gray-900 gap-2'>
                            <div className='text-sm font-medium text-gray-700'>Prix d'achat</div>

                            <div className='text-gray-900'>
                              {formatPrice({ price: rate.purchasePrice })}
                            </div>
                          </div>

                          <div className='flex flex-col text-gray-900 gap-2'>
                            <div className='text-sm font-medium text-gray-700'>Marge minimum</div>

                            <div className='text-gray-900'>
                              {formatPrice({ price: rate.minimumMargin })}
                            </div>
                          </div>

                          <div className='flex flex-col text-gray-900 gap-2'>
                            <div className='text-sm font-medium text-gray-700'>Marge</div>

                            <div className='text-gray-900'>
                              {`${Math.round(((rate.sellingPrice - rate.purchasePrice) / rate.purchasePrice) * 100)} %`}
                            </div>
                          </div>

                          <div className='flex flex-col text-gray-900 gap-2'>
                            <div className='text-sm font-medium text-gray-700'>Prix de vente</div>

                            <div className='text-gray-900'>
                              {formatPrice({ price: rate.sellingPrice })}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className='flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6'>
            <div className='flex justify-end space-x-3'>
              <Button
                isLoading={isSubmitting}
                type='submit'
              >
                {'Modifier'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default OfferEditForm
