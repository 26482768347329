/* eslint-disable typescript-sort-keys/string-enum */
export interface Language {
  code: LanguageCodes
  countryCode: string
  id: number
  name: string
}

export enum LanguageCodes {
  en = 'en',
  fr = 'fr',
  es = 'es'
}

export const languages: Language[] = [
  { code: LanguageCodes.en, countryCode: 'GB', id: 2, name: 'Anglais' },
  { code: LanguageCodes.fr, countryCode: 'FR', id: 1, name: 'Français' },
  { code: LanguageCodes.es, countryCode: 'ES', id: 3, name: 'Espagnol' }
]
