import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layouts/layout/layout'
import { type Hotel } from '@interfaces/api/hotel'
import useApiResourceItemQuery from '@services/api/resources/item-query'

const HotelEntry = () => {
  const { id = '' } = useParams()
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'hotels' })

  const {
    data: hotel
  } = useApiResourceItemQuery<Hotel>({ id, path: 'hotels/{uid}', refetchOnMount: 'always' })

  const breadcrumbs = [
    { href: '/hotels', name: translateResource('title') },
    { href: `/hotels/${hotel?.uid}`, name: hotel?.name.fr ?? '' }
  ]

  return (
    <Layout title={`${translateResource('title')} - ${hotel?.name.fr ?? ''}`}>
      <Guard>

        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <div className='mb-4 mx-auto px-4 sm:px-6 md:px-8'>
          <div className='flex justify-end space-x-2' />

          <div className='border-b border-gray-200 bg-white rounded-lg shadow mt-6'>
            <div className='px-4 py-4'>
              <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>

                <div className='ml-4 mt-4'>
                  <h3
                    className='text-xl font-semibold leading-6 text-slate-700'
                  >
                    {hotel?.name.fr ? hotel.name.fr : ''}
                  </h3>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Guard>
    </Layout>
  )
}

export default HotelEntry
