interface SearchMapField {
  path: string
  searchField: string
}
const SearchMap: Record<string, SearchMapField> = {
  category: {
    path: 'categories',
    searchField: 'name'
  },
  video: {
    path: 'videos',
    searchField: 'originalName'
  }
}

export default SearchMap
