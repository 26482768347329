/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import {
  type RadioFieldsInterfaces
} from '@components/api-resource/api-resource-form/form-fields/radio-fields'
import { fieldIsHidden } from '@components/api-resource/api-resource-form/form-utils/fields'
import { isPropertyType } from '@services/tools/api-resources/property-types'

const RadioFields = ({
  definition,
  fieldsToDisplay,
  fieldsToHide,
  flatTranslatedData,
  requestFields
}: RadioFieldsInterfaces) => {
  const { t } = useTranslation('apiResources')

  const fields: ReactElement[] = []

  // Créez un objet pour associer les noms de champs aux composants correspondants
  const fieldComponents = {}

  requestFields.forEach((field, index) => {
    if ('type' in field.properties) {
      if (
        fieldIsHidden(field, fieldsToDisplay, fieldsToHide) ||
        isPropertyType(field, 'integer') ||
        field.properties.enum ||
        (
          flatTranslatedData.some(data => data.name === field.name) &&
          field.name !== 'title' &&
          field.name !== 'name')
      ) {

      }
    }
  })

  // Parcourez fieldsToDisplay si défini, sinon ajoutez les champs dans l'ordre d'origine
  if (fieldsToDisplay) {
    fieldsToDisplay.forEach((fieldName) => {
      if (fieldComponents[fieldName]) {
        fields.push(fieldComponents[fieldName])
      }
    })
  } else {
    Object.keys(fieldComponents).forEach((fieldName) => {
      fields.push(fieldComponents[fieldName])
    })
  }

  return (
    <>
      {fields}
    </>
  )
}

export default RadioFields
