import { ArrowDownOnSquareIcon, ArrowTopRightOnSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { ErrorMessage, useField, useFormikContext } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { type FormFileFieldProps } from '@components/form-fields/form-image-field/index'
import { captureException } from '@services/exceptions/capture-exception'

const FormImageField = ({ label, name, required = false }: FormFileFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const [imagePreview, setImagePreview] = useState<string>()
  const { setFieldValue } = useFormikContext()
  const [input] = useField({ name })
  const { value } = input

  useEffect(() => {
    if (value?.url) {
      setImagePreview(`https://${value?.url}`)
    }
  }, [value])

  const handleChange = (event) => {
    const file = event?.currentTarget?.files[0]
    setFieldValue(name, file).catch(captureException)
    const fileUrl = URL.createObjectURL(file)
    setImagePreview(fileUrl)
  }

  const removeFile = () => {
    setFieldValue(name, null).catch(captureException)
    setImagePreview('')
    if (inputRef.current?.value) {
      inputRef.current.value = ''
    }
  }

  const openImage = () => {
    window.open(imagePreview, '_blank')
  }

  return (
    <div className='flex flex-col order-1'>
      <label className='block font-medium text-gray-700 text-sm mb-2' htmlFor={name}>{label}</label>

      {imagePreview
        ? (
          <div className='relative h-32 rounded-lg overflow-hidden bg-gray-200 flex justify-between group'>
            <img alt='preview' className='w-full h-full object-cover rounded-md object-center' src={imagePreview} />

            <button className='p-1.5 bg-white rounded-full absolute top-3 right-10 hover:bg-red-500 hover:fill-white' onClick={openImage} type='button'>
              <ArrowTopRightOnSquareIcon className='w-3 h-3' />
            </button>

            <button className='p-1.5 bg-white rounded-full absolute top-3 right-3 hover:bg-red-500 hover:fill-white' onClick={removeFile} type='button'>
              <TrashIcon className='w-3 h-3' />
            </button>
          </div>
        )
        : (
          <div className='relative h-full border-2 border-gray-100 rounded-lg placeholder-gray-400 focus:outline-none flex justify-between px-3 py-6 h-32 group'>
            <input
              className='absolute opacity-0 w-full h-full top-0 left-0 cursor-pointer'
              name={name}
              onChange={handleChange}
              ref={inputRef}
              required={required}
              type='file'
            />

            <div className='w-full flex flex-col gap-1 items-center justify-center text-center'>
              <ArrowDownOnSquareIcon className='w-6 h-6 m-auto' />

              <span className='font-medium'>{translateResource('uploadImage')}</span>

              <span className='text-gray-500 text-xs'>{translateResource('uploadImageDescription')}</span>
            </div>
          </div>
        )
      }

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormImageField
