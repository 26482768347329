import { type Resource } from '@api-platform/api-doc-parser'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ApiPlatformEntity, type ApiPlatformListResponse } from '@interfaces/api/api'
import useAxios from '@services/api/axios'

interface ResourceListParameters {
  definition?: Resource
  parameters?: Record<string, boolean | number | string> | URLSearchParams
}

const useApiResourceListQuery = <T extends ApiPlatformEntity>({ definition, parameters }: ResourceListParameters): UseQueryResult<ApiPlatformListResponse<T>> => {
  const { get } = useAxios()

  const queryKey = [definition?.name, parameters]
  const queryFn = async () => {
    return await get(`api/${definition?.name}`, parameters)
  }
  const staleTime = 5 * 60 * 1000

  return useQuery({
    enabled: !!definition,
    queryFn,
    queryKey,
    staleTime
  })
}

export default useApiResourceListQuery
