import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps'
import React, { useEffect, useRef, useState } from 'react'

interface Props {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void
}

// This is an example of the classic "Place Autocomplete" widget.
// https://developers.google.com/maps/documentation/javascript/place-autocomplete
const PlaceAutocompleteClassic = ({ onPlaceSelect }: Props) => {
  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const places = useMapsLibrary('places')
  const map = useMap()

  useEffect(() => {
    if (!places || !inputRef.current) {
      return
    }

    const options = {
      fields: ['geometry', 'name', 'formatted_address']
    }

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options))
  }, [places])

  useEffect(() => {
    if (!placeAutocomplete) {
      return
    }

    placeAutocomplete.addListener('place_changed', () => {
      const place = placeAutocomplete.getPlace()
      onPlaceSelect(place)
      if (place.geometry?.viewport) {
        map?.fitBounds(place.geometry?.viewport)
      }
    })
  }, [onPlaceSelect, placeAutocomplete])

  return (
    <input className='appearance-none block w-60 mt-2 px-3 py-2 border border-gray-300 bg-gray-50 rounded-lg placeholder-gray-500 focus:outline-none' placeholder='Rechercher' ref={inputRef} />
  )
}

export default PlaceAutocompleteClassic
