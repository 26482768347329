import {
  type DefaultError,
  type UseMutateAsyncFunction, useQueryClient
} from '@tanstack/react-query'

import { type ApiResourceEditProps } from '@components/api-resource/api-resource-edit/index'
import ApiResourceForm, { ApiResourceFormPropsMethod } from '@components/api-resource/api-resource-form'
import useApiResourceItemQuery, { getApiResourceItemQueryUrl } from '@services/api/resources/item-query'
import usePatchEntry from '@services/api/resources/patch-entry-query'
import { captureException } from '@services/exceptions/capture-exception'

const ApiResourceEdit = <T extends never>({ afterSubmit, beforeEdit, children, customAttributes, definition, excludedFields, extraFields, extraValues, fieldsToDisplay, fieldsToHide, id, onEdit, ...props }: ApiResourceEditProps) => {
  /**
   * @ts-expect-error types not declared
   */
  const { data: entityData, isFetching } = useApiResourceItemQuery<T>({ id, path: definition.url, refetchOnMount: 'always', ...props })
  const { mutateAsync: editResourceEntry } = usePatchEntry({ id, path: definition.url })
  const queryKey = getApiResourceItemQueryUrl(definition.url, id)
  const queryClient = useQueryClient()

  /** const reload = () => {
   refetch().catch(captureException)
   }**/

  const onSubmit: UseMutateAsyncFunction<unknown, DefaultError, unknown, unknown> = async (data) => {
    if (beforeEdit) {
      data = beforeEdit(data)
    }
    const result = await editResourceEntry(data)
    // @ts-expect-error result is generic but not typed correctly, if you have time, please fix this.
    onEdit?.(result.uid)

    if (afterSubmit) {
      await afterSubmit(result)
    }

    // Invalidate query after update to get fresh data
    queryClient.invalidateQueries({ queryKey: [queryKey] }).catch(captureException)

    return result
  }

  return (
    <ApiResourceForm
      children={children}
      customAttributes={customAttributes}
      data={entityData}
      definition={definition}
      excludedFields={excludedFields}
      extraFields={extraFields}
      extraValues={extraValues}
      fieldsToDisplay={fieldsToDisplay}
      fieldsToHide={fieldsToHide}
      isFetching={isFetching}
      method={ApiResourceFormPropsMethod.PATCH}
      requestFields={definition.methods.patch.getRequestFields()}
      submitEntry={onSubmit}
    />
  )
}

export default ApiResourceEdit
