import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import { type Board } from '@interfaces/api/board'
import { type AddRoomOptionModalInterfaces } from '@pages/booking-requests/entry/add-room-option-modal/add-room-option-modal.interfaces'
import useItemChildrenQuery from '@services/api/resources/item-children'
import { capitalizeFirstLetter } from '@services/tools/string'

const AddRoomOptionModal = ({ hotel, onAddOption, open, setOpen }: AddRoomOptionModalInterfaces) => {
  const onRoomOptionSelect = (roomBoard: Board) => {
    onAddOption(roomBoard)
    setOpen(false)
  }

  const {
    data: {
      data: roomBoards
    } = {}
  } = useItemChildrenQuery<Board>({
    itemId: hotel.uid,
    path: 'hotels/{uid}/boards'
  })

  return (
    <Dialog
      className='relative z-10'
      onClose={() => {
        setOpen(false)
      }}
      open={open}
    >
      <DialogBackdrop className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out' />

      <div className='fixed inset-0 overflow-hidden'>
        <div className='absolute inset-0 overflow-hidden'>
          <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
            <DialogPanel className='pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out'>
              <div className='flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl'>
                <div className='px-4 sm:px-6'>
                  <div className='flex items-start justify-between'>
                    <DialogTitle className='text-base font-semibold leading-6 text-gray-900'>
                      Ajouter une option
                    </DialogTitle>

                    <button
                      className='relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                      onClick={() => {
                        setOpen(false)
                      }}
                      type='button'
                    >
                      <XMarkIcon aria-hidden='true' className='h-6 w-6' />
                    </button>
                  </div>
                </div>

                <div className='relative mt-6 flex-1 px-4 sm:px-6'>
                  <div className='flex flex-col space-y-2'>
                    {roomBoards?.map((roomBoard: Board, index: number) => (
                      <button
                        className='flex w-full rounded-lg border border-slate-200 p-2 justify-between items-center cursor-pointer whitespace-nowrap'
                        key={`${roomBoard.uid}-${index}`}
                        onClick={() => {
                          onRoomOptionSelect(roomBoard)
                        }}
                        type='button'
                      >
                        {capitalizeFirstLetter(roomBoard.description.fr !== '' ? roomBoard.description.fr : roomBoard.description.en)}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default AddRoomOptionModal
