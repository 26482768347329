/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { type DefaultFieldsInterfaces } from '@components/api-resource/api-resource-form/form-fields/default-fields'
import { fieldIsHidden } from '@components/api-resource/api-resource-form/form-utils/fields'
import FormTextField from '@components/form-fields/form-text-field'
import FormTextAreaField from '@components/form-fields/form-textarea-field'
import FormTimezoneField from '@components/form-fields/form-timezone-field'
import type { ApiPlatformEntity } from '@interfaces/api'
import { isPropertyType } from '@services/tools/api-resources/property-types'

const DefaultFields = <T extends ApiPlatformEntity>({
  data,
  definition,
  fieldsToDisplay,
  fieldsToHide,
  flatTranslatedData,
  requestFields
}: DefaultFieldsInterfaces<T>) => {
  const { t } = useTranslation('apiResources')

  const fields: ReactElement[] = []

  // Créez un objet pour associer les noms de champs aux composants correspondants
  const fieldComponents = {}

  requestFields.forEach((field, index) => {
    if ('type' in field.properties) {
      if (
        fieldIsHidden(field, fieldsToDisplay, fieldsToHide) ||
        isPropertyType(field, 'integer') ||
        field.properties.enum ||
        (flatTranslatedData.some(data => data.name === field.name) && field.name !== 'title' && field.name !== 'name' && field.name !== 'fullName') ||
        (field.name === 'country' || field.name === 'nationality')
      ) {
        return
      }

      if (isPropertyType(field, 'string') && field.properties.format === 'textarea') {
        fieldComponents[field.name] = <FormTextAreaField key={field.name} label={t([`labels.${definition.name}.${field.name}`, `labels.${field.name}`])} name={field.name} required={field.required ?? false} />
      } else if (isPropertyType(field, 'string') && field.properties.format === 'uri') {
        fieldComponents[field.name] = <FormTextField key={field.name} label={t([`labels.${definition.name}.${field.name}`, `labels.${field.name}`])} name={field.name} placeholder={t('placeholder.uri')} required={field.required ?? false} />
      } else if (isPropertyType(field, 'string') && field.properties.format !== 'iri-reference' && field.name !== 'timezone' && field.name !== 'status') {
        fieldComponents[field.name] = <FormTextField key={field.name} label={t([`labels.${definition.name}.${field.name}`, `labels.${field.name}`])} name={field.name} required={field.required ?? false} />
      } else if (field.name === 'timezone') {
        fieldComponents[field.name] = <FormTimezoneField key={field.name} label={t([`labels.${definition.name}.${field.name}`, `labels.${field.name}`])} name={field.name} required={field.required ?? false} />
      }
    }
  })

  // Parcourez fieldsToDisplay si défini, sinon ajoutez les champs dans l'ordre d'origine
  if (fieldsToDisplay) {
    fieldsToDisplay.forEach((fieldName) => {
      if (fieldComponents[fieldName]) {
        fields.push(fieldComponents[fieldName])
      }
    })
  } else {
    Object.keys(fieldComponents).forEach((fieldName) => {
      fields.push(fieldComponents[fieldName])
    })
  }

  return (
    <>
      {fields}
    </>
  )
}

export default DefaultFields
