import { BanknotesIcon } from '@heroicons/react/24/outline'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResource from '@components/api-resource'
import ApiResourceCreate from '@components/api-resource/api-resource-create'
import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layouts/layout/layout'
import Modal from '@components/modals/modal'
import SectionTitle from '@components/section-title/section-title'
import { type CreditPack } from '@interfaces/api/credit-pack'
import useApiResource from '@services/api/definition'
import { captureException } from '@services/exceptions/capture-exception'
import { useIsSuperAdmin } from '@services/hooks/use-is-super-admin'
import { Currency } from '@services/price'

const CreditPacksList = () => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'credit-packs' })
  const [open, setOpen] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [creditPack, setCreditPack] = useState<CreditPack>()
  const isSuperAdmin = useIsSuperAdmin()

  const breadcrumbs = [
    { href: '', name: translateResource('title') }
  ]

  const queryClient = useQueryClient()
  const collectionDefinition = useApiResource('credit-packs')
  const definition = useApiResource('credit-packs/{uid}')

  const onReload = () => {
    queryClient.refetchQueries({ queryKey: ['credit-packs'] }).catch(captureException)
  }

  const onEdit = () => {
    onReload()
    setOpen(false)
    setEditModal(false)
  }

  const onEditClick = (entry) => {
    setCreditPack(entry as CreditPack)
    setEditModal(true)
  }

  const onCreateClick = () => {
    setOpen(true)
  }

  return (
    <Layout title={translateResource('title')}>
      <Guard>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle icon={BanknotesIcon} title={translateResource('title')} />

        <ApiResource
          createdAt={'desc'}
          customFieldsToDisplay={['name', 'credit', 'price', 'createdAt']}
          name={'credit-packs'}
          onClick={onEditClick}
          withCreateOption={isSuperAdmin ? onCreateClick : undefined}
          withDeleteOption
          withEditOption={onEditClick}
        />

        <Modal center open={open} setOpen={setOpen} size='large' title={translateResource('create')}>
          {collectionDefinition && <ApiResourceCreate definition={collectionDefinition} extraValues={{ currency: Currency.EUR }} onEdit={onEdit} />}
        </Modal>

        <>
          {creditPack && (
            <Modal center open={editModal} setOpen={setEditModal} size='large' title={translateResource('edit')}>
              {definition && <ApiResourceEdit definition={definition} id={creditPack.uid} onEdit={onEdit} />}
            </Modal>
          )}
        </>
      </Guard>
    </Layout>
  )
}

export default CreditPacksList
