import {
  useMutation
} from '@tanstack/react-query'

import { type CreateResourceDataParameters } from '@interfaces/api/definition/resource'
import { type Offer } from '@interfaces/api/offer'
import useAxios from '@services/api/axios'

const useCreateEntrySimple = ({ path }) => {
  const { post } = useAxios()

  return useMutation({
    mutationFn: async ({ data }: CreateResourceDataParameters) => {
      const url = `api/${path}`

      return await post<never, Offer>(url, data as never)
    }
  })
}

export default useCreateEntrySimple
