import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { ErrorMessage, FieldArray } from 'formik'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@components/buttons/button'
import IconButton from '@components/buttons/icon-button'
import FormImageField from '@components/form-fields/form-image-field'
import FormNumberField from '@components/form-fields/form-number-field'
import FormSelectField from '@components/form-fields/form-select-field'
import { ExternalPricePlatform } from '@interfaces/api/offer'
import { type ExternalPricesFormInterfaces } from '@pages/booking-requests/components/external-prices-form/external-prices-form.interfaces'

const ExternalPricesForm = ({
  errors,
  externalPrices
}: ExternalPricesFormInterfaces) => {
  const { t } = useTranslation('apiResources')

  const platformOptions = [
    { key: ExternalPricePlatform.BOOKING, name: t(`labels.offers.platforms.${ExternalPricePlatform.BOOKING}`) },
    { key: ExternalPricePlatform.EXPEDIA, name: t(`labels.offers.platforms.${ExternalPricePlatform.EXPEDIA}`) }
  ]

  return (
    <FieldArray
      name='externalPrices'
      render={arrayHelpers => (
        <div className='space-y-4'>
          <div className='divide-y divide-gray-200'>
            {externalPrices.map((price, index) => (
              <div className='flex items-start space-x-4 p-4' key={index}>
                <div className='flex flex-1 space-x-4'>
                  <FormSelectField
                    label='Plateforme'
                    name={`externalPrices.${index}.platform`}
                    options={platformOptions}
                    required
                  />

                  <FormNumberField
                    label='Prix'
                    name={`externalPrices.${index}.price`}
                    required
                    unitComponent={(
                      <div className='border border-gray-300 py-1.5 px-3 text-left text-gray-500 bg-gray-100 border-l-0 rounded-r-md'>
                        {'€'}
                      </div>
                    )}
                    zeroAllowed
                  />

                  <FormImageField
                    label="Capture d'écran"
                    name={`externalPrices.${index}.image`}
                    required
                  />
                </div>

                <IconButton
                  icon={TrashIcon}
                  onClick={() => arrayHelpers.remove(index)}
                />
              </div>
            ))}
          </div>

          <div className='flex flex-col items-end justify-end px-4'>
            <Button
              icon={PlusIcon}
              onClick={() => {
                arrayHelpers.push({
                  image: '',
                  platform: ExternalPricePlatform.BOOKING,
                  price: 0
                })
              }}
              size='small'
              style='secondary'
              type='button'
            >
              Ajouter un prix
            </Button>

            {errors && !Array.isArray(errors.externalPrices) && (
              <ErrorMessage className='px-4 mt-2 text-xs text-red-600 font-medium' component='div' name='externalPrices' />
            )}
          </div>
        </div>
      )}
    />
  )
}

export default ExternalPricesForm
